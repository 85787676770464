<template>
    <v-container fluid>
        <material-card
                title="BI Data">
             <v-container>
                 <v-row style="max-height: 200px; overflow: auto;">
                     <v-col cols="12" sm="6" md="4">
                         <h4>
                             Categories
                         </h4>
                         <v-checkbox v-for="category in categories"
                                     @click="select_category"
                                     v-model="selected_categories" :label="category.name"
                                     :value="category.id">

                         </v-checkbox>
                     </v-col>
                     <v-col cols="12" sm="6" md="4">
                         <h4>
                             Factories
                         </h4>
                         <v-checkbox v-for="factory in factories"
                                     @click="select_factory(factory)"
                                     v-model="selected_factories" :label="factory.organisation.name"
                                     :value="factory.id">

                         </v-checkbox>
                     </v-col>
                     <v-col cols="12" sm="6" md="4">
                         <h4>
                             Customers
                         </h4>
                     </v-col>
                 </v-row>
             </v-container>
        </material-card>
    </v-container>
</template>

<style lang="scss">

</style>

<script>
    export default {
        components: {},
        data() {
            return {
                categories: [],
                factories: [],
                customers: [],
                products: [],
                selected_categories:[],
                selected_factories:[]
            }
        },
        created() {
            this.load_categories()
        },
        methods: {
            select_category() {
                this.factories = [];
                let self = this;
                let cat_ids = '';
                let fact_ids = '';
                for (let i = 0; i < this.selected_categories.length; i++)
                {
                    let category = self.categories.find(o => o.id === self.selected_categories[i]);

                    let facts = category.factories;
                    facts.forEach(function(fc,i){
                        // self.factories.push(o);
                       if(!self.factories.find(o => o.id === fc.id))
                       {
                           self.factories.push(fc)
                       } else {
                           fact_ids += fc.id + '-'
                       }
                    });
                }
                this.load_data();
            },
            select_factory(f)
            {
                this.load_data();
            },
            load_categories() {
                this.$http.get(
                    this.$http.defaults.baseURL + '/bi/categories/').then((response) => {
                    this.categories = response.data;
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            },
            load_data() {
                /**
                 * TODO : generate url http://127.0.0.1:8000/bi/test/?categories=1,2,3&factoires=1&customers=1,2,3,4,5,6&products=10323
                 * seperate ites by coma and pass the filter, calculate qty and dates on the front end ro
                 * send the request 12 times and filter the date for month example for i in 12 get ....
                 */
                this.$http.post(
                    this.$http.defaults.baseURL + '/bi/orders/', {
                        factories: this.selected_factories,
                        categories: this.selected_categories
                    }).then((response) => {
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            },
            generate_filter(filter) {

            }
        }
    }
</script>
